'use client';

import { AlertToast, CheckCircle, Copy05, Typography } from '@v2/ui';
import toast from 'react-hot-toast';

export const AffiliateLink = (props: { affiliateLink: string }) => {
  const onCopyButtonClick = () => {
    const textToCopy = `${process.env.NEXTSITE_URL}${props.affiliateLink}`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.custom(
          (t) => (
            <AlertToast
              {...t}
              title="Success"
              content={'Link has been copied to clipboard!'}
              icon={<CheckCircle />}
            />
          ),
          { position: 'top-right' }
        );
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };
  return (
    <div>
      <h3 className="mb-3 text-xl font-bold text-gray-800 md:mb-5 md:text-2xl">
        Partner Link
      </h3>
      <div className="flex justify-between rounded-xl border border-gray-200 bg-gray-25 p-5">
        <Typography className="w-[26ch] truncate text-gray-500 md:w-auto">
          {process.env.NEXTSITE_URL}
          {props.affiliateLink}
        </Typography>
        <button
          type="button"
          onClick={onCopyButtonClick}
          className="active:opacity-45"
        >
          <Copy05 size="20" className="text-gray-500" />
        </button>
      </div>
    </div>
  );
};
